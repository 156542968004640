import React from "react";
import "./style.css";

export const Desktop = () => {
  return (
    <div className="desktop">
      <div className="div">
        <div className="overlap">
          <div className="rectangle" />
          <div className="frame">
            <div className="frame-wrapper">
              <div className="frame-2">
                <div className="frame-3">
                  <img
                    className="img"
                    alt="From the grounds of"
                    src="/img/from-the-grounds-of-the-middle-east-to-you.png"
                  />
                  <img
                    className="a-more-refined"
                    alt="A more refined"
                    src="/img/a-more-refined-smoking-experience-4.png"
                  />
                </div>
                <img
                  className="khloud-is-a-premium"
                  alt="Khloud is a premium"
                  src="/img/khloud-is-a-premium-dokha-tobacco-hand-blended-from-personally.png"
                />
              </div>
            </div>
            <img className="frame-4" alt="Frame" src="/img/frame-44.png" />
            <img className="frame-4" alt="Frame" src="/img/frame-21.png" />
            <div className="frame-5">
              <div className="its-strength-wrapper">
                <img
                  className="its-strength"
                  alt="Its strength"
                  src="/img/it-s-strength-freshness-and-pure-flavour-is-a-direct-result-of.png"
                />
              </div>
              <div className="frame-6">
                <img className="rectangle-2" alt="Rectangle" src="/img/rectangle-36.png" />
                <img className="rectangle-3" alt="Rectangle" src="/img/rectangle-109.png" />
              </div>
              <div className="group">
                <div className="overlap-group">
                  <div className="rectangle-4" />
                  <div className="rectangle-5" />
                </div>
              </div>
            </div>
            <img className="frame-4" alt="Frame" src="/img/frame-22.jpg" />
            <img className="frame-4" alt="Frame" src="/img/frame-25.jpg" />
            <div className="frame-7">
              <div className="frame-8">
                <p className="we-are-here-to">WE ARE HERE TO PROVIDE</p>
                <div className="the-experiences">THE EXPERIENCES.</div>
              </div>
              <div className="frame-9">
                <div className="div-wrapper">
                  <div className="frame-10">
                    <img className="frame-11" alt="Frame" src="/img/frame-82.svg" />
                    <div className="group-wrapper">
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group-2">
                          <img className="vector" alt="Vector" src="/img/vector-1.svg" />
                          <img
                            className="mini-dropper-bottle"
                            alt="Mini dropper bottle"
                            src="/img/mini-dropper-bottle-mockup-copy-2.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-12">
                  <div className="frame-13">
                    <div className="group-2">
                      <div className="overlap-group-3">
                        <img className="vector-2" alt="Vector" src="/img/vector-1-1.svg" />
                        <img
                          className="mini-dropper-bottle-2"
                          alt="Mini dropper bottle"
                          src="/img/mini-dropper-bottle-mockup-copy-2-1-2.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="frame-14">
                    <div className="frame-15">
                      <div className="text-wrapper">ARABIC CUT</div>
                      <div className="frame-16">
                        <div className="text-wrapper-2">Intensity:</div>
                        <div className="text-wrapper-3">Medium</div>
                      </div>
                      <div className="frame-16">
                        <div className="text-wrapper-2">About:</div>
                        <p className="text-wrapper-3">
                          Premium Arabic cut Dokha blend for a more elongated, authentic smoking experience.
                        </p>
                      </div>
                    </div>
                    <img className="button" alt="Button" src="/img/button.svg" />
                  </div>
                </div>
                <div className="frame-17">
                  <div className="frame-10">
                    <div className="group-wrapper">
                      <div className="group-3">
                        <div className="overlap-group-4">
                          <img className="vector" alt="Vector" src="/img/vector-1-2.svg" />
                          <img
                            className="mini-dropper-bottle-3"
                            alt="Mini dropper bottle"
                            src="/img/mini-dropper-bottle-mockup-copy-3.png"
                          />
                        </div>
                      </div>
                    </div>
                    <img className="frame-18" alt="Frame" src="/img/frame-82-1.svg" />
                  </div>
                </div>
              </div>
            </div>
            <img className="frame-19" alt="Frame" src="/img/image.jpg" />
            <div className="group-4">
              <div className="frame-20">
                <div className="frame-21">
                  <div className="frame-22">
                    <div className="text-wrapper-4">Khloud</div>
                    <div className="frame-23">
                      <div className="text-wrapper-5">Home</div>
                      <div className="text-wrapper-6">Shop</div>
                      <div className="text-wrapper-6">Contact</div>
                    </div>
                  </div>
                  <div className="frame-22">
                    <div className="text-wrapper-4">Policies</div>
                    <div className="frame-23">
                      <div className="text-wrapper-5">Privacy Policy</div>
                      <div className="text-wrapper-6">Shipping Policy</div>
                      <div className="text-wrapper-6">Terms &amp; Conditions</div>
                    </div>
                  </div>
                  <div className="frame-22">
                    <div className="text-wrapper-4">Social</div>
                    <div className="frame-23">
                      <div className="text-wrapper-5">Instagram</div>
                      <div className="text-wrapper-6">Twitter</div>
                      <div className="text-wrapper-6">Facebook</div>
                    </div>
                  </div>
                </div>
                <div className="frame-24">
                  <img className="vector-3" alt="Vector" src="/img/vector-15.svg" />
                  <div className="text-wrapper-7">Copyright 2022 - Khloud®</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="group-5">
          <div className="overlap-group-5">
            <div className="text-wrapper-8">SHOP NOW</div>
          </div>
        </div>
        <div className="overlap-2">
          <img className="button-2" alt="Button" src="/img/button-1.svg" />
          <img className="group-6" alt="Group" src="/img/group.svg" />
          <img className="a-more-refined-2" alt="A more refined" src="/img/a-more-refined-smoking-experience.png" />
        </div>
        <div className="frame-25">
          <div className="frame-26">
            <div className="frame-27">
              <img className="img" alt="Shop" src="/img/shop.png" />
              <img className="img" alt="Contact" src="/img/contact.png" />
            </div>
            <img className="frame-28" alt="Frame" src="/img/frame-120.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};
