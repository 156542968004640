import React from "react";
import "./style.css";

export const DesktopScreen = () => {
  return (
    <div className="desktop-screen">
      <div className="desktop-2">
        <div className="overlap-4">
          <div className="rectangle-10" />
          <div className="frame-64">
            <div className="frame-65">
              <div className="frame-66">
                <div className="frame-67">
                  <img
                    className="img-4"
                    alt="From the grounds of"
                    src="/img/from-the-grounds-of-the-middle-east-to-you-2.png"
                  />
                  <img
                    className="a-more-refined-6"
                    alt="A more refined"
                    src="/img/a-more-refined-smoking-experience-2.png"
                  />
                </div>
                <img
                  className="khloud-is-a-premium-3"
                  alt="Khloud is a premium"
                  src="/img/khloud-is-a-premium-dokha-tobacco-hand-blended-from-personally-2.png"
                />
              </div>
            </div>
            <img className="frame-68" alt="Frame" src="/img/frame-44-2.png" />
            <img className="frame-68" alt="Frame" src="/img/frame-21-2.png" />
            <div className="frame-69">
              <div className="frame-70">
                <img
                  className="its-strength-3"
                  alt="Its strength"
                  src="/img/it-s-strength-freshness-and-pure-flavour-is-a-direct-result-of-2.png"
                />
              </div>
              <div className="frame-71">
                <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-36-2.png" />
                <img className="rectangle-12" alt="Rectangle" src="/img/rectangle-109-2.png" />
              </div>
              <div className="group-11">
                <div className="overlap-group-8">
                  <div className="rectangle-13" />
                  <div className="rectangle-14" />
                </div>
              </div>
            </div>
            <img className="frame-68" alt="Frame" src="/img/frame-22-2.jpg" />
            <img className="frame-68" alt="Frame" src="/img/frame-25-2.jpg" />
            <div className="frame-72">
              <div className="frame-73">
                <p className="we-are-here-to-2">WE ARE HERE TO PROVIDE</p>
                <div className="the-experiences-3">THE EXPERIENCES.</div>
              </div>
              <div className="frame-74">
                <div className="frame-75">
                  <div className="frame-76">
                    <img className="frame-77" alt="Frame" src="/img/frame-82-2.svg" />
                    <div className="frame-78">
                      <div className="group-12">
                        <div className="overlap-group-9">
                          <img className="vector-6" alt="Vector" src="/img/vector-1-4.svg" />
                          <img
                            className="mini-dropper-bottle-5"
                            alt="Mini dropper bottle"
                            src="/img/mini-dropper-bottle-mockup-copy-2-3.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-79">
                  <div className="frame-80">
                    <div className="group-13">
                      <div className="overlap-group-10">
                        <img className="vector-7" alt="Vector" src="/img/vector-1-5.svg" />
                        <img
                          className="mini-dropper-bottle-6"
                          alt="Mini dropper bottle"
                          src="/img/mini-dropper-bottle-mockup-copy-2-1.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="frame-81">
                    <div className="frame-82">
                      <div className="text-wrapper-17">ARABIC CUT</div>
                      <div className="frame-83">
                        <div className="text-wrapper-18">Intensity:</div>
                        <div className="text-wrapper-19">Medium</div>
                      </div>
                      <div className="frame-83">
                        <div className="text-wrapper-18">About:</div>
                        <p className="text-wrapper-19">
                          Premium Arabic cut Dokha blend for a more elongated, authentic smoking experience.
                        </p>
                      </div>
                    </div>
                    <img className="button-5" alt="Button" src="/img/button-5.svg" />
                  </div>
                </div>
                <div className="frame-84">
                  <div className="frame-76">
                    <div className="frame-78">
                      <div className="group-14">
                        <div className="overlap-group-11">
                          <img className="vector-6" alt="Vector" src="/img/vector-1-6.svg" />
                          <img
                            className="mini-dropper-bottle-7"
                            alt="Mini dropper bottle"
                            src="/img/mini-dropper-bottle-mockup-copy-3-1.png"
                          />
                        </div>
                      </div>
                    </div>
                    <img className="frame-85" alt="Frame" src="/img/frame-82-3.svg" />
                  </div>
                </div>
              </div>
            </div>
            <img className="frame-86" alt="Frame" src="/img/frame-98.jpg" />
            <div className="group-15">
              <div className="frame-87">
                <div className="frame-88">
                  <div className="frame-89">
                    <div className="text-wrapper-20">Khloud</div>
                    <div className="frame-90">
                      <div className="text-wrapper-21">Home</div>
                      <div className="text-wrapper-22">Shop</div>
                      <div className="text-wrapper-22">Contact</div>
                    </div>
                  </div>
                  <div className="frame-89">
                    <div className="text-wrapper-20">Policies</div>
                    <div className="frame-90">
                      <div className="text-wrapper-21">Privacy Policy</div>
                      <div className="text-wrapper-22">Shipping Policy</div>
                      <div className="text-wrapper-22">Terms &amp; Conditions</div>
                    </div>
                  </div>
                  <div className="frame-89">
                    <div className="text-wrapper-20">Social</div>
                    <div className="frame-90">
                      <div className="text-wrapper-21">Instagram</div>
                      <div className="text-wrapper-22">Twitter</div>
                      <div className="text-wrapper-22">Facebook</div>
                    </div>
                  </div>
                </div>
                <div className="frame-91">
                  <img className="vector-8" alt="Vector" src="/img/vector-15-2.svg" />
                  <div className="text-wrapper-23">Copyright 2022 - Khloud®</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="group-16">
          <div className="overlap-group-12">
            <div className="text-wrapper-24">SHOP NOW</div>
          </div>
        </div>
        <div className="overlap-5">
          <img className="button-6" alt="Button" src="/img/button-6.svg" />
          <img className="group-17" alt="Group" src="/img/group-2.svg" />
          <img className="a-more-refined-7" alt="A more refined" src="/img/a-more-refined-smoking-experience-3.png" />
        </div>
        <div className="frame-92">
          <div className="frame-93">
            <div className="frame-94">
              <img className="img-4" alt="Shop" src="/img/shop-1.png" />
              <img className="img-4" alt="Contact" src="/img/contact-1.png" />
            </div>
            <img className="frame-95" alt="Frame" src="/img/frame-120-1.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};
